<template>
  <div class="popupLayout">
    <div class="popupDialog" v-show="messages !==null">
      <span class="message" v-text="messages"></span>
      <div class="closeBtn" @click="closePop">
        <svgIcon :type="'close'"></svgIcon>
      </div>
    </div>
  </div>
</template>

<script>
import svgIcon from '@/components/layout/SvgIcon'

export default {
  name: "popupDialog",
  components: {svgIcon},
  data() {
    return {
      messages: null,
    }
  },
  methods: {
    closePop: function () {
      this.messages = null
      this.$bus.$emit('hidePopup')
    },
    updateMessage: function (text) {
      this.messages = text
    }
  },
  mounted() {
    if (!this.$bus._events['popupMessage']) {
      this.$bus.$on('popupMessage', (content) => {

        this.updateMessage(content)
      })
    }

  },
}
</script>
<style lang="scss" scoped>
@import '../../assets/style/popup';

</style>