<template>
  <div class="wrapper" id="wrapper" v-on:scroll="scrollFunction">
    <div class="top-header" :class="thisTabs !== '' ? 'active' : ''">
      <topHeader
        :scrolled="scrolled"
        :scrollNum="scrollNum"
        :showTab="thisTabs"
      ></topHeader>
      <tabs :active="thisTabs"></tabs>
    </div>
    <div class="main" :class="thisTabs !== '' ? 'active' : ''">
      <!--      <intro v-if="thisTabs == 'tab1'"></intro>-->
      <!--&lt;!&ndash;      <schedule v-if="thisTabs == 'tab2'"></schedule>&ndash;&gt;-->
      <!--      <playoff v-if="thisTabs == 'tab2'"></playoff>-->
      <!--      <registration  v-if="thisTabs == 'tab3'"></registration>-->
      <router-view></router-view>
    </div>
    <popupDialog v-show="showPopupDialog"></popupDialog>
  </div>
</template>

<script>
import topHeader from "@/components/layout/topHeader";
import tabs from "@/components/layout/tabs";
import intro from "@/components/content/intro";
import schedule from "@/components/content/schedule";
import playoff from "@/components/content/playoff";
import registration from "@/components/content/registration";
import popupDialog from "@/components/assist/popupDialog";

const logo = require("@/assets/images/logo.png");
export default {
  name: "wrapper",
  components: {
    topHeader,
    tabs,
    popupDialog,
    intro,
    schedule,
    playoff,
    registration,
  },
  data() {
    return {
      logo: logo,
      scrolled: false,
      scrollNum: 0,
      thisTabs: "",
      showPopupDialog: false,
    };
  },
  computed: {},
  methods: {
    scrollFunction: function() {
      var top = document.getElementById("wrapper").getBoundingClientRect().top;
      this.scrollNum = top;
      this.scrolled = top < -100 ? true : false;
    },
  },
  mounted: function() {
    this.$bus.$on("goBack", () => {
      this.thisTabs = "";
      this.$router.push({ name: "Home" }).catch(() => {});
    });
    if (this.$route.name == "Registration") {
      this.thisTabs = "tab3";
    }
    this.$bus.$on("registration", () => {
      this.thisTabs = "tab3";
    });
    this.$bus.$on("chooseTabs", (el) => {
      this.thisTabs = el;
      if (el !== "tab3") {
        this.$router.push({ name: "Home" }).catch(() => {});
      }
      window.scrollTo(0, 0);
    });
    this.$bus.$on("showPopup", () => {
      this.showPopupDialog = true;
    });
    this.$bus.$on("hidePopup", () => {
      this.showPopupDialog = false;
    });
  },
  created() {
    window.addEventListener("scroll", this.scrollFunction);
  },
  watch: {},
};
</script>

<style lang="scss">
.top-header {
  &.active {
    width: 100%;
    overflow: hidden;
    position: absolute;
  }
}
</style>
