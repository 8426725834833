<template>
  <div class="sponsor">
    <div class="items">
      <div class="item" v-for="(sponsor,i) in sponsors" :key="i">
        <a target="_blank" :href="sponsor.link">
          <img :src="sponsor.image" alt="">
        </a>
      </div>
    </div>
  </div>
</template>

<script>
const sponsor6 = require('@/assets/images/sponsor6.png');
const sponsor2 = require('@/assets/images/sponsor2.png');
const sponsor3 = require('@/assets/images/sponsor3.png');
const sponsor5 = require('@/assets/images/sponsor5.png');
export default {
  name: "sponsor",
  data() {
    return {
      sponsors: [
        {
          image: sponsor6,
          link: 'https://17.live/zh-Hant'
        },
        {
          image: sponsor5,
          link: 'https://treyotaiwan.com.tw/?rstrk=boygametest'
        },
        {
          image: sponsor3,
          link: 'https://odotoo.com/'
        },
        {
          image: sponsor2,
          link: 'https://www.instagram.com/olivialiuliu/ '
        },
      ]
    }
  },
}
</script>

<style lang="scss">

.sponsor {
  padding-top: 1rem;
  .title {
    width: 100%;
    text-align: center;
    font-family: 'Hiragino Kaku Gothic Std';
    font-size: 1.5rem;
  }

  .items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    .item {
      padding: 0 2.5rem;

      img {
        width: 100%;
      }
    }

    @include responsive('max', 1050px) {
      grid-template-columns: 1fr 1fr ;
    }
  }

}
</style>