<template>
  <div class="event">
    <div class="title">
      玄武盃賽事
    </div>
    <div class="schedule">
      <div class="item" v-for="(item, i) in schedule" :key="i">
        <div class="header">
          <span class="date">{{ item.date }}</span>
          <span class="day">{{ item.day }}</span>
        </div>
        <div class="tbody">
          <div class="type">
            <span class="type">{{ item.type }}</span>
            <span class="text">GROUP STAGE</span>
          </div>
          <div class="teams">
            <div
              class="team"
              v-for="(team, i) in item.teams"
              :key="i"
              :style="`background-color: ${findTeam(team).color}`"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="title">玄武盃選手 {{ active }}</div>
    <div class="teams">
      <div class="teamIcons">
        <div class="icon" v-for="(team, i) in teamLine1" :key="i">
          <img
            :src="team.icon"
            @click="active = active == team.team ? '' : team.team"
          />
        </div>
      </div>
      <div class="preview">
        <img :src="image" v-for="(image, i) in previewPlayers" :key="i" />
      </div>
      <div class="teamIcons">
        <div class="icon" v-for="(team, i) in teamLine2" :key="i">
          <img
            :src="team.icon"
            @click="active = active == team.team ? '' : team.team"
          />
        </div>
      </div>
    </div>
    <rules></rules>
  </div>
</template>

<script>
import rules from "@/components/content/rules";
const A = require("@/assets/images/team-3/teamAicon_300x300.svg");
const B = require("@/assets/images/team-3/teamBicon_300x300.svg");
const C = require("@/assets/images/team-3/teamCicon_300x300.svg");
const D = require("@/assets/images/team-3/teamDicon_300x300.svg");
const E = require("@/assets/images/team-3/teamEicon_300x300.svg");
const F = require("@/assets/images/team-3/teamFicon_300x300.svg");
const G = require("@/assets/images/team-3/teamGicon_300x300.svg");
const H = require("@/assets/images/team-3/teamHicon_300x300.svg");

const A1 = require("@/assets/images/player-3/A1.jpg");
const A2 = require("@/assets/images/player-3/A2.jpg");
const B1 = require("@/assets/images/player-3/B1.jpg");
const B2 = require("@/assets/images/player-3/B2.jpg");
const C1 = require("@/assets/images/player-3/C1.jpg");
const C2 = require("@/assets/images/player-3/C2.jpg");
const D1 = require("@/assets/images/player-3/D1.jpg");
const D2 = require("@/assets/images/player-3/D2.jpg");
const E1 = require("@/assets/images/player-3/E1.jpg");
const E2 = require("@/assets/images/player-3/E2.jpg");
const F1 = require("@/assets/images/player-3/F1.jpg");
const F2 = require("@/assets/images/player-3/F2.jpg");
const G1 = require("@/assets/images/player-3/G1.jpg");
const G2 = require("@/assets/images/player-3/G2.jpg");
const H1 = require("@/assets/images/player-3/H1.jpg");
const H2 = require("@/assets/images/player-3/H2.jpg");

export default {
  name: "intro",
  components: { rules },
  data() {
    return {
      teamLine1: [
        { team: "A", icon: A, color: "#9A7735", players: [A1, A2] },
        { team: "B", icon: B, color: "#FF834B", players: [B1, B2] },
        { team: "C", icon: C, color: "#A755EA", players: [C1, C2] },
        { team: "D", icon: D, color: "#008E91", players: [D1, D2] },
      ],
      teamLine2: [
        { team: "E", icon: E, color: "#FFC82C", players: [E1, E2] },
        { team: "F", icon: F, color: "#CC1F30", players: [F1, F2] },
        { team: "G", icon: G, color: "#98D34B", players: [G1, G2] },
        { team: "H", icon: H, color: "#42A5E2", players: [H1, H2] },
      ],
      schedule: [
        { date: "11.11", day: "MON.", type: "N", teams: ["A", "G", "F", "H"] },
        { date: "11.12", day: "TUE.", type: "S", teams: ["B", "C", "D", "E"] },
        { date: "11.18", day: "MON.", type: "N", teams: ["A", "G", "F", "H"] },
        { date: "11.19", day: "TUE.", type: "S", teams: ["B", "C", "D", "E"] },
        { date: "11.25", day: "MON.", type: "N", teams: ["A", "G", "F", "H"] },
        { date: "11.26", day: "TUE.", type: "S", teams: ["B", "C", "D", "E"] },
      ],
      active: "",
      season: {},
      teams: {},
      showDetail: "",
    };
  },
  computed: {
    teamPlayers: function() {
      return this.teamLine1.concat(this.teamLine2);
    },
    previewPlayers: function() {
      return this.active ? this.findTeam(this.active).players : [];
    },
  },
  methods: {
    findTeam: function(team) {
      return this.teamPlayers.filter((el) => el.team == team)[0];
    },
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
@import "../../assets/style/event";
.hidden {
  display: none;
}
.notice {
  width: 100%;
  text-align: center;
  font-family: Noto Sans TC;
  color: $main-color-1;
  display: flex;
  flex-direction: column;
  padding-top: 0.5rem;
  a {
    color: $main-color-1;
  }

  font-weight: 900;
  @include responsive("min", 768px) {
    font-size: 1.5rem;
  }
  @include responsive("max", 767.5px) {
    font-size: 1rem;
  }
}
</style>
