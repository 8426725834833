<template>
  <div class="intro">
    <img class="full-img" :src="image.title" alt="" />
    <p class="icontent">
      SML這是一個以「台灣麻將」為核心的競技比賽品牌，並結合實境秀的娛樂元素，可以突顯比賽不僅僅是技術性的麻將對決，還包括選手的個性、策略思維、團隊合作、情感羈絆等要素。
    </p>
    <div class="img-block">
      <img
        class="full-img"
        :src="item"
        v-for="(item, i) in image.blocks"
        :key="i"
        alt=""
      />
    </div>
    <p class="icontent">
      每一季挑選的參賽者，從初選到最後組成八支隊伍的過程，每位選手都需要在很短的時間內，快速展現個人特質，追求獲勝及逆轉的能力。進而讓收看直播的觀眾，能感同身受進入刺激複雜的牌局之中。
    </p>
    <img class="full-img" :src="image.group1" alt="" />
    <img class="full-img" :src="image.group2" alt="" />
    <p class="icontent">
      玄武盃是自2020年11月發起的麒麟盃賽季之後，第三季的比賽企劃。以「玄武」的幾何圖樣，代表著參賽隊伍中的團結穩固；以不同方向從原點射出，則代表著無限的可能性。
    </p>
    <p class="icontent">
      主播＆賽評
    </p>
    <img class="full-img" :src="image.anchor" alt="" />
    <div class="anchors-table">
      <div class="item">
        <div class="name">主播 - 伯夷</div>
        <div class="td">
          伯夷打麻將頻道創立人，製作麻將相關影片10年的時間。先前以安安打多大賽事節目累積經驗，從校園、各行各業選手與不同主題中成長，升級團隊人力與器材後，創建SML麻將賽事直播品牌。希望藉由SML讓賭博與麻將不再畫上等號，將競技元素帶到下一個境界。
        </div>
      </div>
      <div class="item">
        <div class="name">賽評 - 肯尼</div>
        <div class="td">
          2021年SML鳳凰盃E隊選手，賽後加入伯夷打麻將直播團隊，參與直播與企劃。在多次單場、短期賽事中以關主身分出場。高中職以下鳳雛盃為隊伍之小隊長。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const title = require("@/assets/images/intro/title.jpg");
const web01 = require("@/assets/images/intro/web01.jpg");
const web02 = require("@/assets/images/intro/web02.jpg");
const web03 = require("@/assets/images/intro/web03.jpg");
const group1 = require("@/assets/images/intro/group1.jpg");
const group2 = require("@/assets/images/intro/group2.jpg");
const anchor = require("@/assets/images/intro/anchor.jpg");

export default {
  name: "intro",

  data() {
    return {
      image: {
        title: title,
        blocks: [web01, web02, web03],
        group1: group1,
        group2: group2,
        anchor: anchor,
      },
      active: "",
      season: {},
      teams: {},
      showDetail: "",
    };
  },
  methods: {
    chooseTabs: function(el) {
      var tab = this.active == el ? "" : el;
      this.active = tab;
      this.$bus.$emit("chooseTabs", tab);
    },
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
@import "../../assets/style/intro";
.hidden {
  display: none;
}
.notice {
  width: 100%;
  text-align: center;
  font-family: Noto Sans TC;
  color: $main-color-1;
  display: flex;
  flex-direction: column;
  padding-top: 0.5rem;
  a {
    color: $main-color-1;
  }

  font-weight: 900;
  @include responsive("min", 768px) {
    font-size: 1.5rem;
  }
  @include responsive("max", 767.5px) {
    font-size: 1rem;
  }
}
</style>
