<template>
  <div class="top-banner">
    <div class="origin-banner" :class="showTab.length == 0 ? 'active' : ''">
      <div
        class="content"
        :style="{
          'background-image': `url(${banner})`,
          'background-position-x': `${originBgPosition}px`,
        }"
      ></div>
    </div>
    <div class="lite-banner" :class="showTab.length == 0 ? '' : 'active'" @click="goBack()">
      <div
        class="content"
        :style="{
          'background-image': `url(${litebanner})`,
          'background-position-x': `${liteBgPosition}px`,
        }"
      ></div>
    </div>
  </div>
</template>

<script>
const banner = require("@/assets/images/xuanwu/xuanwu_banner.svg");
const litebanner = require("@/assets/images/xuanwu/banner.svg");
export default {
  name: "topHeader",
  props: ["scrolled", "scrollNum", "showTab"],
  data() {
    return {
      originBgPosition: 0,
      liteBgPosition: 0,
      banner: banner,
      litebanner: litebanner,
      mobNavActive: false,
    };
  },
  methods: {
    goBack: function() {
      this.$bus.$emit("goBack");
    },
  },
  mounted() {
    setInterval(() => {
      this.originBgPosition = this.originBgPosition - 1;
      this.liteBgPosition = this.liteBgPosition - 1;
    }, 50);
  },
  watch: {
    showTab: {
      handler: function() {
        this.originBgPosition = 0;
        this.liteBgPosition = 0;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/style/header";
</style>
